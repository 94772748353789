.avatar {
  border-radius: 100%;
  height: 10rem;
}

.switchContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.switchContainer button {
  margin-left: 1rem;
  height: 42px;
  width: 150px;
  font-size: 1rem;
  font-family: Roboto;
  font-weight: 800;
}

.header {
  display: flex;
  max-height: 240px;
  padding: 30px 0;
  background: #f5f5f5;
}

.bgChange {
  display: flex;
  max-height: 240px;
  padding: 30px 0;
  background-color: rgba(0,0,0,.85) !important;
  color: #fff !important;
}

.bgChange .profileContent h1, .bgChange .profileContent h2, .bgChange .profileContent h3 {
  color: #fafafa;
}

@media only screen and (max-width: 767px) {
  .container {
    flex-direction: column;
    align-items: center;
  }
  .profileContent {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .contact {
    margin-top: 2rem;
  }
  .header {
    max-height: 600px;
  }
  .bgChange {
    max-height: 500px;
  }
  .switchContainer > .lastButton {
    margin-top: 0rem;
  }
}

@media only screen and (max-width: 510px) {
.switchContainer > .lastButton {
  margin-top: 1rem;
}
}

@media only screen and (min-width: 767px) and (max-width: 920px) {
  .switchContainer > .lastButton {
    margin-top: 1rem;
  }
  }

.container:last-child{
  flex-grow: 1;
}

.profileContent {
  flex-grow: 1;
  margin-left: 1rem;
}

.profileContent h1, .profileContent h2 {
  margin-top: 0;
  margin-bottom: 0;
}

.profileContent h1 {
  color: #49515a;
  font-size: 38px;
  margin-bottom: 5px;
  margin-top: 30px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}

.profileContent h2 {
  color: #778492;
  font-family: "Lato", arial, sans-serif;
  font-weight: 400;
  font-size: 24px;
  margin-top: 0;
}

.profileContent h3 {
  color: #778492;
  font-family: "Lato", arial, sans-serif;
  font-weight: 400;
  font-size: 24px;
  margin-top: 5px;
  margin-bottom: 15px;
}

.container {
  display: flex;
  align-items: center;
  margin: 2rem;
}

.bgColorSVG {
  display: flex;
  list-style: none;
  padding-left: 0;
  margin-top: 0;
}

.bgColorSVG li {
  margin-right: 1rem;
  height: 50px;
  width: 50px;
}

.bgColorSVG li a {
  display: flex;
  height: 46px;
  width: 46px;
  background-color: #dedede;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
}

.bgColorSVG li a:hover {
  background-color: #7e7e7e;
  color: #fafafa;
  transition: 0.5s;
}

.bgColorSVG li svg{
  color: rgb(0,0,0,.85);
}

.listInline {
 display: flex;
 list-style: none;
 padding-left: 0;
 margin-top: 0;
}

.listInline li {
  margin-right: 1rem;
  height: 50px;
  width: 50px;
}

.listInline li a {
  display: flex;
  height: 46px;
  width: 46px;
  background-color: lightgray;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
}

.listInline li a:hover {
  background-color: #5f6b77;
  transition: 0.5s;
}

.listInline li svg{
  color: #fff;
}

.profileContent svg {
  height: 25px;
  /* margin-top: 8px; */
}

.FindOutMore {
  height: 1rem;
  margin-left: 1rem;
}