.bgChange {
  padding: 30px;
  margin-bottom: 30px;
  margin-top: 30px;
  margin-right: 1rem;
  margin-left: 1rem;
  background-color: rgba(0,0,0,.85) !important;
  color: #fafafa;
}

.bgChange h2 {
  color: #fafafa;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 30px;
  font-size: 24px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}

.bgChange h4 {
  font-family: "Lato", arial, sans-serif;
  font-size: 13px;
  color: #fafafa;
  font-weight: 600;
  padding-left: 25px;
  margin-top: 0;
}

.bgChange h4, .bgChange .eduSection h5, .bgChange h4 span, .bgChange .eduSection svg {
  color: #fafafa;
}

.innerSection {
  background: #fff;
  padding: 30px;
  margin-bottom: 30px;
  margin-top: 30px;
  margin-right: 1rem;
  margin-left: 1rem;
}

.innerSection h2 {
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 30px;
  color: #545e69;
  font-size: 24px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}

.content {
  font-size: 16px;
}

.progressLevel li{
  display: flex;
  flex-wrap: wrap;
}

.progressLevel li h5{
  flex-grow: 1;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: #778492;
}

.progressLevel span {
  color: #ccd1d6;
  font-size: 16px;
  font-weight: 400;
  font-family: "Lato", arial, sans-serif;
}

.progressLevel span i{
  font-size: 20px;
  color: #fff;
  background-color: rgb(150, 150, 150);
  border-radius: 100%;
  cursor: pointer;
}

.eduSection {
  display: flex;
  align-items: flex-start;
  margin-right: 1rem;
}

.eduSection svg{
  width: 1.25em;
  margin-right: .5rem;
  color: #778492;
}

.eduSection h5 {
  font-size: 16px;
  margin-top: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: #778492;
}

.innerSection h4 {
  font-family: "Lato", arial, sans-serif;
  font-size: 13px;
  color: #999;
  font-weight: 600;
  padding-left: 25px;
  margin-top: 0;
}

.innerSection h4 span {
  color: #b0b7bf;
  font-weight: 500;
}