.innerSection {
  background: #fff;
  padding: 30px;
  margin-bottom: 30px;
  margin-top: 30px;
  margin-right: 1rem;
  margin-left: 1rem;
}

.bgChange {
  padding: 30px;
  margin-bottom: 30px;
  margin-top: 30px;
  margin-right: 1rem;
  margin-left: 1rem;
  background-color: rgba(0,0,0,.85) !important;
  color: #fff !important;
}

.bgChange h2 {
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 30px;
  color: #fafafa;
  font-size: 24px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}

.innerSection h2 {
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 30px;
  color: #545e69;
  font-size: 24px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}

.content {
  font-size: 16px;
}