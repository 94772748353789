.bgChange {
  padding: 30px;
  margin-bottom: 30px;
  margin-top: 30px;
  margin-right: 1rem;
  margin-left: 1rem;
  background-color: rgba(0,0,0,.85) !important;
  color: #fafafa;
}

.bgChange h2 {
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 30px;
  color: #fafafa;
  font-size: 24px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}

.bgChange .frameworks, .bgChange ul li h5 {
  color: #fafafa;
}

.innerSection {
  background: #fff;
  padding: 30px;
  margin-bottom: 30px;
  margin-top: 30px;
  margin-right: 1rem;
  margin-left: 1rem;
}

.innerSection h2 {
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 30px;
  color: #545e69;
  font-size: 24px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}

.content {
  font-size: 16px;
}

.progressLevel li{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.progressLevel li h5{
  flex-grow: 1;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: #778492;
}

.frameworks {
  font-weight: 600;
  color: #778492;
}

.progressLevel span {
  color: #727272;
  font-size: 16px;
  font-weight: 400;
  font-family: "Lato", arial, sans-serif;
  display: flex;
  align-items: flex-end;
}