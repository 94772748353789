* {
  font-family: 'Roboto', sans-serif;
}

.bg-change {
  background-color: rgba(0,0,0,.85);
  color: #fff;
}

.bg-container-color {
  background-color: rgba(0,0,0,.7);
}

.App-container {
  background-color: #DAE3E7;
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.nav-wrapper ul li {
  margin-left: .5rem;
  margin-right: .5rem;
  cursor: pointer;
}

.nav-wrapper a {
  margin-left: 1rem;
  cursor: pointer;
}

.App-outer-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.App-inner-container {
  flex-grow: 1;
  display: flex;
}

.main-container {
  max-width: 100%;
  margin-right: auto;
}

.side-container {
  max-width: 70vw;
  margin-right: auto;
}

.container {
  margin-left: 2rem;
  margin-right: 2rem;
  width: 100% !important;
  margin: 0 auto !important;
  max-width: 1360px !important;
}

.App-inner-container h1 {
  text-align: center;
}

.brand-logo {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.nav-wrapper {
  background-color: #1e88e5 ;
}

.hero {
  margin-left: 0rem;
  width: 100%;
  min-height: 40vh;
}

.main {
  display: flex;
  flex-wrap: wrap;
}

.box {
  border: 1px solid #000;
  height: 20rem;
  width: 30rem;
  margin: 1rem;
}

.App-footer {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@media only screen and (max-width: 1150px) {
  .App-inner-container {
    flex-direction: column;
  }
  .side-container {
    max-width: 100vw;
    margin-right: auto;
  }

}